// Import a Proxima Nove Adobe Font
@import url('https://use.typekit.net/szi8tin.css');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

// Set your brand colors

// colors from Sasha
$brand-dark: #008DA0;
$brand-light: #00F2D0;
$brand-lighter: #DEFDF9;

$white-light: #FBFBFE;
$white-dark: #F3F3F3;

$grey-lighter: #CCC;
$grey-light: #9B9B9B;
$grey-dark: #384243;
$black: #000000;
$linked-in: #0077b5;

// Update Bulma's global variables
// $family-primary: 'proxima-nova', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-primary: 'Nunito Sans', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-sans-serif: $family-primary;
$primary: $grey-light;
$link: $brand-light;
$widescreen-enabled: true;
$fullhd-enabled: false;

// font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));

// $body-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));

// $size-01: 4.2rem;
// $size-1: 3.6rem;
// $size-2: 1.8rem;
// $size-3: 1.35rem;
// $size-4: 1.25rem;
// $size-5: 1.2rem;
// $size-6: 1rem;
// $size-7: 0.9rem;
// $size-8: 0.7rem;

// Update some of Bulma's component variables
$body-background-color: $white-light;
$control-border-width: 0.1rem;

// $input-border-: transparent;
$input-shadow: none;
$input-radius: 0.05rem;
$input-color: $grey-dark;
$input-background-color: $white-light;

// Navbar
$navbar-divider-background-color: $white-dark;
$navbar-gradient-color: linear-gradient($grey-dark, $grey-light);

$navbar-item-color: $white-dark;
$navbar-background-color: $grey-dark;

// For mouse hover
$navbar-dropdown-background-color: $grey-dark;
$navbar-dropdown-item-hover-color: $white-light;
$navbar-dropdown-item-hover-background-color: $grey-light;

// For mouse hover
$navbar-item-hover-color: $white-light;
$navbar-item-hover-background-color: $grey-light;

// For class is-active
$navbar-item-active-color: $brand-light;
$navbar-item-active-background-color: $brand-light;

// Menu
$menu-item-hover-color: $grey-dark;
$menu-item-hover-background-color: $brand-light;
$menu-item-active-color: $brand-lighter;
$menu-item-active-background-color: $brand-dark;

// Footer
$contact-us-color: $grey-light;
$contact-us-color-hover: $white-light;

$table-head-background-color: $grey-dark;
$table-head-cell-color: $white-dark;

@import "../../node_modules/bulma/bulma.sass";
